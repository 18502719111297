import { Box, Text } from "@mantine/core";
import { Colors } from "../../../styles/colors";

type ReviewCardsProps = {
  largeScreen: boolean;
};

const REVIEWS = [
  {
    name: "smor1297",
    text: "If you enjoy the daily NYTimes games, you'll love this app!",
  },
  {
    name: "Arty_C",
    text: "These are very different from any other games you'll find on the app store.",
  },
  {
    name: "Rivet City",
    text: "As someone who enjoys a challenge, this is the perfect puzzle app!",
  },
];

export const ReviewCards = ({ largeScreen }: ReviewCardsProps) => (
  <Box
    style={{
      display: "flex",
      gap: largeScreen ? "2rem" : "1.5rem",
      flexDirection: largeScreen ? "row" : "column",
      alignItems: "stretch",
      justifyContent: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
    }}
  >
    {REVIEWS.map((review) => (
      <Box
        key={review.name}
        style={{
          padding: "2rem",
          borderRadius: "15px",
          background: "var(--glass-bg)",
          backdropFilter: "var(--glass-blur-sm)",
          border: "1px solid var(--purple-15)",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text
          size="lg"
          style={{
            fontStyle: "italic",
            marginBottom: "1rem",
            lineHeight: 1.6,
          }}
        >
          "{review.text}"
        </Text>

        <Text
          size="sm"
          fw={500}
          style={{
            color: Colors.PURPLE,
            alignSelf: "flex-end",
          }}
        >
          - {review.name}
        </Text>
      </Box>
    ))}
  </Box>
);
