import { Box } from "@mantine/core";
import { useState, useRef } from "react";

type PromoVideoProps = {
  largeScreen: boolean;
};

export const PromoVideo = ({ largeScreen }: PromoVideoProps) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: largeScreen ? "800px" : "100%",
        margin: "0 auto",
        borderRadius: "20px",
        overflow: "hidden",
        border: largeScreen ? "1px solid var(--purple-15)" : "none",
        boxShadow: largeScreen ? "0 8px 32px rgba(0, 0, 0, 0.2)" : "none",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={togglePlayPause}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "100%",
          height: "auto",
          display: "block",
        }}
      >
        <source src="promo_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Play/Pause Overlay */}
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: isPlaying ? 0 : 1,
          transition: "opacity 0.2s ease",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
          style={{ marginLeft: isPlaying ? 0 : "3px" }}
        >
          {isPlaying ? (
            <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
          ) : (
            <path d="M8 5v14l11-7z" />
          )}
        </svg>
      </Box>
    </Box>
  );
};
