import "./styles/global.css";
import { MantineProvider } from "@mantine/core";
import NavigationWrapper from "./navigation/NavigationWrapper";
import { createUseStyles } from "react-jss";
import { Colors, Gradients } from "./styles/colors";

const useStyles = createUseStyles(() => ({
  "@global": {
    "html, body": {
      margin: 0,
      padding: 0,
      minHeight: "100vh",
      background: Gradients.DARK_GRADIENT,
      backgroundAttachment: "fixed",
      color: Colors.WHITE,
    },
    "#root": {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    "*": {
      boxSizing: "border-box",
    },
  },
}));

const App = () => {
  useStyles();

  return (
    <MantineProvider
      theme={{
        fontFamily: "MontserratExtraBold",
        headings: { fontFamily: "MontserratBlack" },
        other: {
          meta: [
            { name: "apple-mobile-web-app-capable", content: "yes" },
            {
              name: "apple-mobile-web-app-status-bar-style",
              content: "black-translucent",
            },
            { name: "theme-color", content: Colors.BLACK },
          ],
        },
      }}
    >
      <NavigationWrapper />
    </MantineProvider>
  );
};

export default App;
